import React, { useCallback, useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useParams } from "react-router-dom";
import protectedApiInstance from "../utils/protectedApiInstance";

const SingleUserPage = () => {
  const { id } = useParams();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: null,
    gender: "",
    profileImg: null,
    status: true,
    addressList: [], // Initialize addressList
  });
  const [imageUrl, setImageUrl] = useState(null);

  // Fetch user details based on user ID
  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await protectedApiInstance.get(`/admin/users/details/${id}`);
      setUser(response.data.user);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]); // ✅ No warning now

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
        setUser({ ...user, profileImg: file });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleDateChange = (e) => {
    setUser({ ...user, dob: e.target.value }); // Directly set the value in YYYY-MM-DD format
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit User Information</h5>
            <div className="row nav-column">
              <div>
                <form className="row">
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={user.firstName}
                      onChange={handleInputChange}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={user.lastName}
                      onChange={handleInputChange}
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={user.email}
                      onChange={handleInputChange}
                      placeholder="Email"
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      value={user.phone}
                      onChange={handleInputChange}
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Date of Birth</label>
                    <input
                      type="date"
                      className="form-control"
                      name="dob"
                      value={user.dob ? user.dob.split("T")[0] : ""}
                      onChange={handleDateChange}
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Gender</label>
                    <select
                      className="form-control"
                      name="gender"
                      value={user.gender}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Profile Image</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        alt="Profile Preview"
                        style={{
                          width: "100px",
                          height: "100px",
                          marginTop: "10px",
                        }}
                      />
                    )}
                  </div>

                  {/* Read-only Address List */}
                  <div className="col-lg-12">
                    <h5>Address List</h5>
                    {user.addressList.length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Address</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Country</th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                            <th>Favourite</th>
                          </tr>
                        </thead>
                        <tbody>
                          {user.addressList.map((address, index) => (
                            <tr key={index}>
                              <td>{address.address}</td>
                              <td>{address.city}</td>
                              <td>{address.state}</td>
                              <td>{address.country}</td>
                              <td>{address.latitude}</td>
                              <td>{address.longitude}</td>
                              <td>{address.favourite ? "Yes" : "No"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>No addresses available.</p>
                    )}
                  </div>

                  <div className="col-lg-12">
                    <button type="submit" className="btn btn-success">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleUserPage;
