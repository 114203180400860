import React, { useCallback, useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import moment from "moment";
import protectedApiInstance from "../utils/protectedApiInstance";
import Pagination from "../components/Pagination";

const SosPage = () => {
  const [sosList, setSosList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");

  const fetchSos = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await protectedApiInstance.get("/admin/sos/list", {
        params: {
          page: currentPage,
          pageSize,
          search: searchQuery,
          sortBy,
          sortOrder,
        },
      });

      setSosList(res.data.sosList || []);
      setTotalPages(res.data.totalPages || 0);
    } catch (error) {
      console.error("Failed to fetch SOS list:", error);
      setSosList([]);
      setTotalPages(0);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, pageSize, searchQuery, sortBy, sortOrder]);

  useEffect(() => {
    fetchSos();
  }, [fetchSos]);

  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="container">
          <div className="row foot-tablerow">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5>SOS Alerts</h5>
            </div>

            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto" style={{ minHeight: "300px" }}>
                <div className="table-controls d-flex justify-content-between mb-3">
                  <div className="d-flex align-items-center">
                    <select
                      id="entriesPerPage"
                      className="form-select mr-2"
                      onChange={(e) => setPageSize(parseInt(e.target.value))}
                      value={pageSize}
                      style={{ marginRight: "0.5rem" }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    <label htmlFor="entriesPerPage" className="mb-0" style={{ whiteSpace: "nowrap" }}>
                      Entries per page
                    </label>
                  </div>

                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Name / Phone / Type"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>

                <table className="table1" id="datatable">
                  <thead>
                    <tr>
                      <th className="text-center">No</th>
                      <th onClick={() => handleSort("user.firstName")} style={{ cursor: "pointer" }}>
                        Name {sortBy === "user.firstName" && (sortOrder === "asc" ? "↑" : "↓")}
                      </th>
                      <th>Phone</th>
                      <th onClick={() => handleSort("supportType")} style={{ cursor: "pointer" }}>
                        Support Type {sortBy === "supportType" && (sortOrder === "asc" ? "↑" : "↓")}
                      </th>
                      <th>Address</th>
                      <th>Status</th>
                      <th onClick={() => handleSort("createdAt")} style={{ cursor: "pointer" }}>
                        Created {sortBy === "createdAt" && (sortOrder === "asc" ? "↑" : "↓")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="7">
                          <div className="d-flex justify-content-center align-items-center py-5 w-100" style={{ minHeight: "200px" }}>
                            <div className="spinner-border text-primary" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : sosList.length > 0 ? (
                      sosList.map((sos, index) => (
                        <tr key={sos._id}>
                          <td className="text-center">
                            {(currentPage - 1) * pageSize + index + 1}
                          </td>
                          <td>{sos.user?.firstName || "N/A"} {sos.user?.lastName || ""}</td>
                          <td>{sos.user?.phone || "N/A"}</td>
                          <td>{sos.supportType}</td>
                          <td>{sos.address || "—"}</td>
                          <td>
                            <span className={`badge ${sos.resolved ? "bg-success" : "bg-danger"}`}>
                              {sos.resolved ? "Resolved" : "Unresolved"}
                            </span>
                          </td>
                          <td>{moment(sos.createdAt).format("DD-MM-YYYY")}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center py-5 text-muted">
                          No SOS alerts found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    {sosList.length > 0
                      ? `Showing entries ${(currentPage - 1) * pageSize + 1} to ${Math.min(
                          currentPage * pageSize,
                          (currentPage - 1) * pageSize + sosList.length
                        )} of ${(currentPage - 1) * pageSize + sosList.length} entries`
                      : "Showing entries 0 to 0 of 0 entries"}
                  </div>
                  <Pagination
                    currentPage={sosList.length > 0 ? currentPage : 0}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SosPage;
