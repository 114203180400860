import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const navigate = useNavigate();
  const [redirectPath, setRedirectPath] = useState("/");

  useEffect(() => {
    const token = localStorage.getItem("admintoken");
    if (token) {
      setRedirectPath("/dashboard");
    }
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-center vh-100 bg-light">
      <div className="text-center p-4 bg-white shadow-lg rounded">
        <h1 className="display-1 fw-bold text-danger">404</h1>
        <p className="fs-4 text-muted">Oops! The page you're looking for doesn't exist.</p>
        <button
          onClick={() => navigate(redirectPath)}
          className="btn btn-primary mt-3"
        >
          Go {redirectPath === "/dashboard" ? "to Dashboard" : "Home"}
        </button>
      </div>
    </div>
  );
}

