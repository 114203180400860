import React, { useCallback, useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import protectedApiInstance from "../utils/protectedApiInstance";
import iziToast from "izitoast";
import Pagination from "../components/Pagination";
import { Modal } from "react-bootstrap";

const UserHelpSupportOptionsPage = () => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddModal, setShowAddModal] = useState(false); // Add Option Modal
  const [newOptionTitle, setNewOptionTitle] = useState(""); // New Option Title
  const [showEditModal, setShowEditModal] = useState(false); // Edit Option Modal
  const [editOption, setEditOption] = useState(null); // Option being edited
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Delete Confirmation Modal
  const [optionIdToDelete, setOptionIdToDelete] = useState(null); // Option ID to delete
  const [totalItems, setTotalItems] = useState(0); // Add this state

  const fetchOptions = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await protectedApiInstance.get(
        "/admin/help-and-support/user/options",
        {
          params: {
            page: currentPage,
            pageSize,
            search: searchQuery,
          },
        }
      );
      setOptions(response.data.options);
      setTotalItems(response.data.pagination.totalItems);
      setTotalPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error("Error fetching options:", error);
      iziToast.error({
        title: "Error",
        message: "Failed to fetch options",
        position: "topRight",
      });
      setOptions([]);
      setTotalItems(0);
      setTotalPages(0);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, pageSize, searchQuery]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const addOption = async () => {
    if (!newOptionTitle.trim()) {
      iziToast.error({
        title: "Error",
        message: "Title is required",
        position: "topRight",
      });
      return;
    }

    try {
      setIsLoading(true);
      await protectedApiInstance.post("/admin/help-and-support/user/options", {
        title: newOptionTitle,
      });
      iziToast.success({
        title: "Success",
        message: "Option added successfully",
        position: "topRight",
      });
      setShowAddModal(false); // Close the modal
      setNewOptionTitle(""); // Reset the input field
      fetchOptions(); // Refresh the options list
    } catch (error) {
      console.error("Error adding option:", error);
      iziToast.error({
        title: "Error",
        message: "Failed to add option",
        position: "topRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const editOptionHandler = async () => {
    if (!editOption.title.trim()) {
      iziToast.error({
        title: "Error",
        message: "Title is required",
        position: "topRight",
      });
      return;
    }

    try {
      setIsLoading(true);
      await protectedApiInstance.put(
        `/admin/help-and-support/user/options/${editOption._id}`,
        {
          title: editOption.title,
        }
      );
      iziToast.success({
        title: "Success",
        message: "Option updated successfully",
        position: "topRight",
      });
      setShowEditModal(false); // Close the modal
      setEditOption(null); // Reset the edit option
      fetchOptions(); // Refresh the options list
    } catch (error) {
      console.error("Error updating option:", error);
      iziToast.error({
        title: "Error",
        message: "Failed to update option",
        position: "topRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const swapPosition = async (id, direction) => {
    try {
      setIsLoading(true);
      await protectedApiInstance.post(
        "/admin/help-and-support/user/options/swap",
        {
          id,
          direction,
        }
      );
      iziToast.success({
        title: "Success",
        message: "Position swapped successfully",
        position: "topRight",
      });
      fetchOptions(); // Refresh the options list
    } catch (error) {
      console.error("Error swapping position:", error);
      iziToast.error({
        title: "Error",
        message: "Failed to swap position",
        position: "topRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteOption = async () => {
    try {
      setIsLoading(true);
      await protectedApiInstance.delete(
        `/admin/help-and-support/user/options/${optionIdToDelete}`
      );
      iziToast.success({
        title: "Success",
        message: "Option deleted successfully",
        position: "topRight",
      });
      setShowDeleteModal(false); // Close the modal
      fetchOptions(); // Refresh the options list
    } catch (error) {
      console.error("Error deleting option:", error);
      iziToast.error({
        title: "Error",
        message: "Failed to delete option",
        position: "topRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePageSizeChange = (e) => {
    const newPageSize = parseInt(e.target.value);
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset to the first page when page size changes
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="container">
          <div className="row foot-tablerow">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5>User Help & Support Options</h5>
              <button
                className="btn btn-primary"
                onClick={() => setShowAddModal(true)}
              >
                Add User Option
              </button>
            </div>

            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                {/* Table Controls */}
                <div className="table-controls d-flex justify-content-between mb-3">
                  <div className="d-flex align-items-center">
                    <select
                      id="entriesPerPage"
                      className="form-select mr-2"
                      onChange={handlePageSizeChange} // Update handler function
                      value={pageSize}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>

                    <label
                      htmlFor="entriesPerPage"
                      className="mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Entries per page
                    </label>
                  </div>

                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by title"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>

                {/* Table */}
                <table id="datatable" className="table1">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Title</th>
                      <th>Position</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {options?.length > 0 ? (
                      options.map((option, index) => (
                        <tr key={option._id}>
                          <td className="text-center">
                            {(currentPage - 1) * pageSize + index + 1}
                          </td>
                          <td>{option.title}</td>
                          <td>{option.position}</td>
                          <td>
                            <i
                              className={`fa-solid fa-arrow-up ${
                                option.position === 1 ? "disabled" : ""
                              }`}
                              onClick={() =>
                                option.position > 1 &&
                                swapPosition(option._id, "up")
                              }
                              style={{
                                cursor:
                                  option.position === 1
                                    ? "not-allowed"
                                    : "pointer",
                                marginRight: "10px",
                                color: option.position === 1 ? "grey" : "black",
                              }}
                            ></i>
                            <i
                              className={`fa-solid fa-arrow-down ${
                                option.position === totalItems ? "disabled" : ""
                              }`}
                              onClick={() =>
                                option.position < totalItems &&
                                swapPosition(option._id, "down")
                              }
                              style={{
                                cursor:
                                  option.position === totalItems
                                    ? "not-allowed"
                                    : "pointer",
                                marginRight: "10px",
                                color:
                                  option.position === totalItems
                                    ? "grey"
                                    : "black",
                              }}
                            ></i>

                            <i
                              className="fa-solid fa-pen-to-square"
                              onClick={() => {
                                setEditOption(option);
                                setShowEditModal(true);
                              }}
                              style={{ cursor: "pointer", marginRight: "10px" }}
                            ></i>
                            <i
                              className="fa-solid fa-trash"
                              onClick={() => {
                                setOptionIdToDelete(option._id);
                                setShowDeleteModal(true);
                              }}
                              style={{ cursor: "pointer", color: "red" }}
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No options found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {/* Pagination */}
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    {options?.length > 0
                      ? `Showing entries ${
                          (currentPage - 1) * pageSize + 1
                        } to ${Math.min(
                          currentPage * pageSize,
                          totalItems // Use totalItems directly from API
                        )} of ${totalItems} entries`
                      : "Showing entries 0 to 0 of 0 entries"}
                  </div>
                  <Pagination
                    currentPage={options?.length > 0 ? currentPage : 0}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Add Option Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add User Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder="Enter option title"
            value={newOptionTitle}
            onChange={(e) => setNewOptionTitle(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="modal-footer-btn"
            onClick={addOption}
            disabled={isLoading}
          >
            {isLoading ? "Adding..." : "Add"}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Edit Option Modal */}
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit User Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder="Enter new option title"
            value={editOption?.title || ""}
            onChange={(e) =>
              setEditOption({ ...editOption, title: e.target.value })
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="modal-footer-btn"
            onClick={editOptionHandler}
            disabled={isLoading}
          >
            {isLoading ? "Updating..." : "Update"}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this option?</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={deleteOption}
            disabled={isLoading}
          >
            {isLoading ? "Deleting..." : "Delete"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserHelpSupportOptionsPage;
