import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import protectedApiInstance from "../utils/protectedApiInstance";
import iziToast from "izitoast";
import SideBar from "./SideBar";
import Navbar from "./Navbar";

const AddEditCouponPage = () => {
  const { id: couponId } = useParams(); // Get couponId from URL parameters
  const navigate = useNavigate();

  const [coupon, setCoupon] = useState({
    couponName: "",
    couponCode: "",
    couponDiscount: 0,
    couponEnabled: false,
    forNewUser: false,
    validFrom: "",
    validUntil: "",
    maxUsagePerUser: 1,
    maximumDiscountPrice: 0,
    description: "",
    details: [""],
  });

  const [applyMaxUsageRestriction, setApplyMaxUsageRestriction] =
    useState(false); // Checkbox state
  const [isLoading, setIsLoading] = useState(false);

  const fetchCouponDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await protectedApiInstance.get(
        `/coupon-code/details/${couponId}`
      );

      const formatDateToIndian = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-IN"); // DD/MM/YYYY
      };

      const formatDateToISO = (dateString) => {
        const [day, month, year] = dateString.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      };

      const couponData = {
        ...response.data.couponCodeData,
        validFrom: formatDateToISO(
          formatDateToIndian(response.data.couponCodeData.validFrom)
        ),
        validUntil: formatDateToISO(
          formatDateToIndian(response.data.couponCodeData.validUntil)
        ),
        details: response.data.couponCodeData.details || [""],
      };

      setCoupon(couponData);
      setApplyMaxUsageRestriction(couponData.maxUsagePerUser !== null);
    } catch (error) {
      console.error("Error fetching coupon details:", error);
      iziToast.error({
        title: "Error",
        message: "Failed to fetch coupon details",
        position: "topRight",
      });
    } finally {
      setIsLoading(false);
    }
  }, [couponId]);

  useEffect(() => {
    if (couponId) {
      fetchCouponDetails();
    }
  }, [couponId, fetchCouponDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCoupon((prevCoupon) => ({ ...prevCoupon, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCoupon((prevCoupon) => ({ ...prevCoupon, [name]: checked }));
  };

  const handleDetailChange = (index, value) => {
    const updatedDetails = [...coupon.details];
    updatedDetails[index] = value;
    setCoupon((prevCoupon) => ({ ...prevCoupon, details: updatedDetails }));
  };

  const addDetailField = () => {
    setCoupon((prevCoupon) => ({
      ...prevCoupon,
      details: [...prevCoupon.details, ""],
    }));
  };

  const removeDetailField = (index) => {
    const updatedDetails = [...coupon.details];
    updatedDetails.splice(index, 1);
    setCoupon((prevCoupon) => ({ ...prevCoupon, details: updatedDetails }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formatToISTOffset = (dateString) => {
      const date = new Date(dateString);

      // Adjust to IST by adding 5 hours 30 minutes
      const istDate = new Date(date.getTime() + 5.5 * 60 * 60 * 1000);

      const [yyyy, mm, dd] = [
        istDate.getFullYear(),
        istDate.getMonth() + 1,
        istDate.getDate(),
      ];

      // IST offset string
      const offset = "+05:30";

      return `${yyyy}-${String(mm).padStart(2, "0")}-${String(dd).padStart(
        2,
        "0"
      )}T00:00:00.000${offset}`;
    };

    const payload = {
      ...coupon,
      validFrom: formatToISTOffset(coupon.validFrom),
      validUntil: formatToISTOffset(coupon.validUntil),
    };

    try {
      if (!applyMaxUsageRestriction) {
        coupon.maxUsagePerUser = null; // If checkbox is not checked, set to null
      }

      if (couponId) {
        await protectedApiInstance.put(
          `/coupon-code/details/${couponId}`,
          payload
        );
        iziToast.success({
          title: "Success",
          message: "Coupon updated successfully",
          position: "topRight",
        });
      } else {
        await protectedApiInstance.post("/coupon-code/add", payload);
        iziToast.success({
          title: "Success",
          message: "Coupon created successfully",
          position: "topRight",
        });
      }
      navigate("/coupons");
    } catch (error) {
      iziToast.error({
        title: "Error",
        message: "Failed to save coupon",
        position: "topRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="container mt-5">
          <h2>{couponId ? "Edit Coupon" : "Add Coupon"}</h2>
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="mb-3">
              <label htmlFor="couponName" className="form-label">
                Coupon Name
              </label>
              <input
                type="text"
                className="form-control"
                id="couponName"
                name="couponName"
                value={coupon.couponName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="couponCode" className="form-label">
                Coupon Code
              </label>
              <input
                type="text"
                className="form-control"
                id="couponCode"
                name="couponCode"
                value={coupon.couponCode}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="couponDiscount" className="form-label">
                Discount (%)
              </label>
              <input
                type="number"
                className="form-control"
                id="couponDiscount"
                name="couponDiscount"
                value={coupon.couponDiscount}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="maximumDiscountPrice" className="form-label">
                Maximum Discount Price
              </label>
              <input
                type="number"
                className="form-control"
                id="maximumDiscountPrice"
                name="maximumDiscountPrice"
                value={coupon.maximumDiscountPrice}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="validFrom" className="form-label">
                Valid From
              </label>
              <input
                type="date"
                className="form-control"
                id="validFrom"
                name="validFrom"
                value={coupon.validFrom}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="validUntil" className="form-label">
                Valid Until
              </label>
              <input
                type="date"
                className="form-control"
                id="validUntil"
                name="validUntil"
                value={coupon.validUntil}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Apply Maximum Usage Restriction Checkbox */}
            <div className="form-check mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="applyMaxUsageRestriction"
                checked={applyMaxUsageRestriction}
                onChange={() =>
                  setApplyMaxUsageRestriction(!applyMaxUsageRestriction)
                }
              />
              <label
                htmlFor="applyMaxUsageRestriction"
                className="form-check-label"
              >
                Apply Maximum Usage Restrictions
              </label>
            </div>

            {applyMaxUsageRestriction && (
              <div className="mb-3">
                <label htmlFor="maxUsagePerUser" className="form-label">
                  Max Usage Per User
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="maxUsagePerUser"
                  name="maxUsagePerUser"
                  value={coupon.maxUsagePerUser}
                  onChange={handleInputChange}
                />
              </div>
            )}

            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                value={coupon.description}
                onChange={handleInputChange}
                rows="3"
                required
              ></textarea>
            </div>
            <div className="mb-3">
              <label>Details</label>
              {coupon.details.map((detail, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <input
                    type="text"
                    className="form-control"
                    value={detail}
                    onChange={(e) => handleDetailChange(index, e.target.value)}
                    required
                  />
                  <button
                    type="button"
                    className="btn btn-danger btn-sm ml-2"
                    onClick={() => removeDetailField(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={addDetailField}
              >
                Add Detail
              </button>
            </div>
            <div className="form-check mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="couponEnabled"
                name="couponEnabled"
                checked={coupon.couponEnabled}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="couponEnabled" className="form-check-label">
                Enabled
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="forNewUser"
                name="forNewUser"
                checked={coupon.forNewUser}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="forNewUser" className="form-check-label">
                For New Users
              </label>
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                type="button"
                className="btn btn-secondary ms-2"
                onClick={() => navigate("/coupons")}
                disabled={isLoading}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default AddEditCouponPage;
