// utils/protectedApiInstance.js
import axios from 'axios';
const apipath = process.env.REACT_APP_API_PATH;
// Create an Axios instance with default settings
const protectedApiInstance = axios.create({
    baseURL: apipath, // Set the base API path
});

// Add a request interceptor to include the adminToken in every request
protectedApiInstance.interceptors.request.use(
    (config) => {
        const adminToken = localStorage.getItem('admintoken'); // Retrieve admintoken from localStorage
        console.log(adminToken);

        if (adminToken) {
            // Attach token to Authorization header
            config.headers['Authorization'] = `Bearer ${adminToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default protectedApiInstance;
