import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import iziToast from 'izitoast';
import protectedApiInstance from "../utils/protectedApiInstance";

const ReferBonusModal = ({ show, onClose, initialValues = {}, fetchBonusValues }) => {
    const [bonusValues, setBonusValues] = useState({
        new_user_bonus_from_user: initialValues.new_user_bonus_from_user || 0,
        new_driver_bonus_from_driver: initialValues.new_driver_bonus_from_driver || 0,
        new_user_bonus_from_driver: initialValues.new_user_bonus_from_driver || 0,
        new_driver_bonus_from_user: initialValues.new_driver_bonus_from_user || 0,
        referrer_bonus_user_to_user: initialValues.referrer_bonus_user_to_user || 0,
        referrer_bonus_driver_to_driver: initialValues.referrer_bonus_driver_to_driver || 0,
        referrer_bonus_user_to_driver: initialValues.referrer_bonus_user_to_driver || 0,
        referrer_bonus_driver_to_user: initialValues.referrer_bonus_driver_to_user || 0,
        new_user_bonus_without_referrer: initialValues.new_user_bonus_without_referrer || 0,
        new_driver_bonus_without_referrer: initialValues.new_driver_bonus_without_referrer || 0,
    });

    const [isSaving, setIsSaving] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBonusValues((prev) => ({
            ...prev,
            [name]: parseFloat(value) || 0,
        }));
    };

    const handleSave = async () => {
        try {
            setIsSaving(true);

            await protectedApiInstance.put('/admin/refer/bonuses', bonusValues);

            iziToast.success({
                title: 'Success',
                message: 'Referral bonuses updated successfully.',
                position: 'topRight',
            });

            fetchBonusValues();
            onClose();
        } catch (error) {
            console.error('Error updating referral bonuses:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to update referral bonuses.',
                position: 'topRight',
            });
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Modal show={show} onHide={onClose} centered size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Edit Referral Bonuses</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* First Row (4 Fields) */}
                    <Row>
                        {[
                            { label: "User → User Bonus (EtoCoins)", name: "new_user_bonus_from_user" },
                            { label: "Driver → Driver Bonus (Wallet)", name: "new_driver_bonus_from_driver" },
                            { label: "Driver → User Bonus (EtoCoins)", name: "new_user_bonus_from_driver" },
                            { label: "User → Driver Bonus (Wallet)", name: "new_driver_bonus_from_user" }
                        ].map((bonus) => (
                            <Col md={6} lg={3} key={bonus.name}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{bonus.label}</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name={bonus.name}
                                        value={bonusValues[bonus.name]}
                                        onChange={handleChange}
                                        min={0}
                                    />
                                </Form.Group>
                            </Col>
                        ))}
                    </Row>

                    {/* Second Row (4 Fields) */}
                    <Row>
                        {[
                            { label: "Referrer (User → User) (EtoCoins)", name: "referrer_bonus_user_to_user" },
                            { label: "Referrer (Driver → Driver) (Wallet)", name: "referrer_bonus_driver_to_driver" },
                            { label: "Referrer (User → Driver) (EtoCoins)", name: "referrer_bonus_user_to_driver" },
                            { label: "Referrer (Driver → User) (Wallet)", name: "referrer_bonus_driver_to_user" }
                        ].map((bonus) => (
                            <Col md={6} lg={3} key={bonus.name}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{bonus.label}</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name={bonus.name}
                                        value={bonusValues[bonus.name]}
                                        onChange={handleChange}
                                        min={0}
                                    />
                                </Form.Group>
                            </Col>
                        ))}
                    </Row>

                    {/* Third Row (2 Fields) */}
                    <Row>
                        {[
                            { label: "New User Bonus (No Referrer) (EtoCoins)", name: "new_user_bonus_without_referrer" },
                            { label: "New Driver Bonus (No Referrer) (Wallet)", name: "new_driver_bonus_without_referrer" }
                        ].map((bonus) => (
                            <Col md={6} key={bonus.name}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{bonus.label}</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name={bonus.name}
                                        value={bonusValues[bonus.name]}
                                        onChange={handleChange}
                                        min={0}
                                    />
                                </Form.Group>
                            </Col>
                        ))}
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose} disabled={isSaving}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave} disabled={isSaving}>
                    {isSaving ? 'Saving...' : 'Save Changes'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReferBonusModal;
