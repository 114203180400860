import iziToast from "izitoast";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const SideBar = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  // const [activeSubmenu, setActiveSubmenu] = useState(null);
  const location = useLocation();

  // const adminname = localStorage.getItem("adminname");
  // const adminemail = localStorage.getItem("adminemail");
  const admintoken = localStorage.getItem("admintoken");

  if (!admintoken) {
    navigate("/");
  }

  const handleLogout = () => {
    localStorage.removeItem("admintoken");
    localStorage.removeItem("adminname");
    localStorage.removeItem("adminemail");
    localStorage.removeItem("adminId");
    iziToast.success({ message: "Logout successful", position: "topCenter" });
    window.location.href = "/";
  };

  // const toggleSubmenu = (index) => {
  //   setActiveSubmenu(activeSubmenu === index ? null : index);
  // };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const isTabActive = (path) => {
    return location.pathname === path ? "activeTab" : "";
  };

  const menuItems = [
    {
      path: "/dashboard",
      icon: "bi bi-grid-3x3",
      label: "Dashboard",
    },
    {
      path: "/users",
      icon: "bi bi-people",
      label: "Users Management",
    },
    {
      path: "/drivers",
      icon: "bi bi-person",
      label: "Driver Management",
    },
    {
      path: "/bookings",
      icon: "bi bi-book",
      label: "Booking Management",
    },
    {
      path: "/driver-ride-accept",
      icon: "bi bi-database",
      label: "Driver Ride Management",
    },
    {
      path: "/payments",
      icon: "bi bi-cash",
      label: "Ride Payments",
    },
    {
      path: "/footeredit",
      icon: "bi bi-gear",
      label: "Payout Management",
    },
    {
      path: "/coupons",
      icon: "bi bi-ticket-perforated",
      label: "Coupon Code Management",
    },
    {
      path: "/fare",
      icon: "bi bi-currency-rupee",
      label: "Fare Management",
    },
    {
      path: "/locations",
      icon: "bi bi-geo-alt",
      label: "Locations Management",
    },
    {
      path: "/promotions",
      icon: "bi bi-geo-alt",
      label: "Promotions Management",
    },
    {
      path: "/sos",
      icon: "bi bi-geo-alt",
      label: "SOS Management",
    },
    // CMS
    {
      section: "CMS Section",
    },
    {
      path: "/refer-bonus",
      icon: "bi bi-cash-coin",
      label: "Refer Bonus",
    },
    {
      path: "/user-help-support-options",
      icon: "bi bi-info-circle",
      label: "User Help & Support Options",
    },
    {
      path: "/user-help-support-contents",
      icon: "bi bi-info-circle",
      label: "User Help & Support Contents",
    },
    {
      path: "/driver-help-support-options",
      icon: "bi bi-info-circle",
      label: "Driver Help & Support Options",
    },
    {
      path: "/driver-help-support-contents",
      icon: "bi bi-info-circle",
      label: "Driver Help & Support Contents",
    },
    // Policy
    {
      section: "Policy Section",
    },
    {
      path: "/termsedit",
      icon: "bi bi-shield-fill-check",
      label: "Terms & Conditions",
    },
    {
      path: "/privacyedit",
      icon: "bi bi-shield-fill-check",
      label: "Privacy Policy",
    },
    {
      path: "/refundedit",
      icon: "bi bi-shield-fill-check",
      label: "Refund & Cancellation Policy",
    },
    {
      path: "/shippingedit",
      icon: "bi bi-shield-fill-check",
      label: "Shipping & Delivery Policy",
    },
    {
      path: "/contactusedit",
      icon: "bi bi-shield-fill-check",
      label: "Contact us",
    },
  ];

  return (
    <>
      <div
        className={`sidebar ${
          isActive ? "close" : ""
        } border-end border-2 border-success`}
      >
        <Link className="logo-box text-decoration-none" onClick={toggleSidebar}>
          {/* <i className="bi bi-menu-down"></i> */}
          <img
            className="mt-4 mx-5"
            src={`${process.env.PUBLIC_URL}/imgs/logo.jpeg`}
            width={140}
            height={25}
            alt=""
          />
        </Link>

        <ul className="sidebar-list">
          {menuItems.map((item, index) => {
            if (item.section) {
              return (
                <React.Fragment key={index}>
                  <hr />
                  <center>
                    <h5>{item.section}</h5>
                  </center>
                  <hr />
                </React.Fragment>
              );
            }

            return (
              <li key={index}>
                <div className="title">
                  <Link
                    to={item.path}
                    className={`link ${isTabActive(item.path)}`}
                  >
                    <i className={item.icon}></i>
                    <span className="name">{item.label}</span>
                  </Link>
                </div>
                <div className="submenu">
                  <Link to={item.path} className="link submenu-title">
                    {item.label}
                  </Link>
                </div>
              </li>
            );
          })}

          <li className="logout">
            <div className="title">
              <Link
                to="#"
                className="link"
                onClick={handleLogout}
                style={{
                  color: "red",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i
                  className="bi bi-box-arrow-left"
                  style={{ color: "red" }}
                ></i>
                <span className="text-danger">Logout</span>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideBar;
