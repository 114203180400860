import React, { useCallback, useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import moment from "moment";
import protectedApiInstance from "../utils/protectedApiInstance";
import Pagination from "../components/Pagination";

const RidePaymentsPage = () => {
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");

  const fetchPayments = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await protectedApiInstance.get("/admin/payment", {
        params: {
          page: currentPage,
          pageSize,
          search: searchQuery,
          sortBy,
          sortOrder,
        },
      });

      setPayments(res.data.payments || []);
      setTotalPages(res.data.totalPages || 0);
    } catch (error) {
      console.error("Failed to fetch payments:", error);
      setPayments([]);
      setTotalPages(0);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, pageSize, searchQuery, sortBy, sortOrder]);

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="container">
          <div className="row foot-tablerow">
            {/* Heading */}
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5>Ride Payments</h5>
              {/* If you ever need a button on the right, add it here */}
            </div>

            <div className="col-lg-12 maintable-column">
              <div
                className="container mt-4 overflow-auto"
                style={{ minHeight: "300px" }}
              >
                {/* Table Controls: Entries per page & Search */}
                <div className="table-controls d-flex justify-content-between mb-3">
                  <div className="d-flex align-items-center">
                    <select
                      id="entriesPerPage"
                      className="form-select mr-2"
                      onChange={(e) => setPageSize(parseInt(e.target.value))}
                      value={pageSize}
                      style={{ marginRight: "0.5rem" }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    <label
                      htmlFor="entriesPerPage"
                      className="mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Entries per page
                    </label>
                  </div>

                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Transaction/User/Driver ID"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>

                {/* Data Table */}
                <table id="datatable" className="table1">
                  <thead>
                    <tr>
                      <th className="text-center">No</th>
                      <th
                        onClick={() => handleSort("transactionId")}
                        style={{ cursor: "pointer" }}
                      >
                        Transaction ID{" "}
                        {sortBy === "transactionId" &&
                          (sortOrder === "asc" ? "↑" : "↓")}
                      </th>
                      <th>Amount</th>
                      <th
                        onClick={() => handleSort("paymentMethod")}
                        style={{ cursor: "pointer" }}
                      >
                        Method{" "}
                        {sortBy === "paymentMethod" &&
                          (sortOrder === "asc" ? "↑" : "↓")}
                      </th>
                      <th>Distance (km)</th>
                      <th>Status</th>
                      <th
                        onClick={() => handleSort("createdAt")}
                        style={{ cursor: "pointer" }}
                      >
                        Created{" "}
                        {sortBy === "createdAt" &&
                          (sortOrder === "asc" ? "↑" : "↓")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="7">
                          <div
                            className="d-flex justify-content-center align-items-center py-5 w-100"
                            style={{ minHeight: "200px" }}
                          >
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : payments.length > 0 ? (
                      payments.map((payment, index) => (
                        <tr key={payment._id}>
                          <td className="text-center">
                            {(currentPage - 1) * pageSize + index + 1}
                          </td>
                          <td>{payment.transactionId || "N/A"}</td>
                          <td>₹{payment.amount.toFixed(2)}</td>
                          <td>{payment.paymentMethod}</td>
                          <td>{payment.rideDistance}</td>
                          <td>
                            <span
                              className={`badge ${
                                payment.paymentStatus === "Confirmed"
                                  ? "bg-success"
                                  : "bg-warning text-dark"
                              }`}
                            >
                              {payment.paymentStatus}
                            </span>
                          </td>
                          <td>
                            {moment(payment.createdAt).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center py-5 text-muted">
                          No payments found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {/* Pagination & Entries Info */}
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    {payments.length > 0
                      ? `Showing entries ${
                          (currentPage - 1) * pageSize + 1
                        } to ${Math.min(
                          currentPage * pageSize,
                          (currentPage - 1) * pageSize + payments.length
                        )} of ${
                          (currentPage - 1) * pageSize + payments.length
                        } entries`
                      : "Showing entries 0 to 0 of 0 entries"}
                  </div>
                  <Pagination
                    currentPage={payments.length > 0 ? currentPage : 0}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RidePaymentsPage;
