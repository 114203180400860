// import React, { createContext, useContext, useState, useEffect } from 'react';
// import {jwtDecode} from 'jwt-decode';

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   // const apipath = "http://localhost:3030/api/v1";
//   // const filepath = "http://localhost:3030/images/";

//   // const apipath = "https://etorides.com:3000/api/v1";
//   // const filepath = "https://etorides.com:3000/images/";

//   const apipath = "https://beta.etorides.com:3030/api/v1";
//   const filepath = "https://beta.etorides.com:3030/images/";

//   const login = () => {
//     setIsLoggedIn(true);
//   };

//   const adminLogout = () => {
//     localStorage.removeItem('admintoken');
//     localStorage.removeItem('adminname');
//     localStorage.removeItem('adminemail');
//     localStorage.removeItem('adminId');
//     setIsLoggedIn(false);
//     window.location.href = "/";
//   };

//   const checkTokenExpiration = () => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       const decodedToken = jwtDecode(token);
//       const currentTime = Date.now() / 1000;

//       if (decodedToken.exp < currentTime) {
//         adminLogout();
//       }
//     }
//   };

//   useEffect(() => {
//     checkTokenExpiration();
//     const interval = setInterval(() => {
//       checkTokenExpiration();
//     }, 60000); // Check every 1 minute

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <AuthContext.Provider value={{ isLoggedIn, apipath, login, adminLogout, filepath }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => useContext(AuthContext);

// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const apipath = "https://etorides.com:3000/api/v1";
  // const filepath = "https://etorides.com:3000/images/";

  // const apipath = "https://beta.etorides.com:3030/api/v1";
  // const filepath = "https://beta.etorides.com:3030/images/";

  const apipath = process.env.REACT_APP_API_PATH;

  const filepath = "http://localhost:3030/images/";



  const login = () => {
    setIsLoggedIn(true);
  };

  // `adminLogout` now optionally accepts a callback for navigation
  const adminLogout = (navigate) => {
    localStorage.removeItem('admintoken');
    localStorage.removeItem('adminname');
    localStorage.removeItem('adminemail');
    localStorage.removeItem('adminId');
    setIsLoggedIn(false);
    if (navigate) navigate("/");
  };

  const checkTokenExpiration = () => {
    const token = localStorage.getItem('admintoken');
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        adminLogout();
      } else {
        // Set timeout for auto-logout based on token expiration
        const timeUntilLogout = (decodedToken.exp - currentTime) * 1000;
        setTimeout(() => {
          adminLogout();
        }, timeUntilLogout);
      }
    }
  };

  useEffect(() => {
    checkTokenExpiration();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, apipath, login, adminLogout, filepath }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
