import React, { useState, useEffect } from "react";
import iziToast from "izitoast";
import protectedApiInstance from "../utils/protectedApiInstance";

const AddEditLocation = ({ location: initialLocation, onClose, onSuccess }) => {
  const [location, setLocation] = useState({
    title: "",
    city: "",
    state: "",
    country: "",
    latitude: "",
    longitude: "",
    radius: "",
    status: false,
  });

  // Pre-fill the form when editing an existing location
  useEffect(() => {
    if (initialLocation) {
      setLocation(initialLocation);
    }
  }, [initialLocation]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocation((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (initialLocation) {
        await protectedApiInstance.put(
          `/locations/details/${initialLocation._id}`,
          location
        );
        iziToast.success({
          title: "Success",
          message: "Location updated successfully",
          position: "topRight",
        });
      } else {
        await protectedApiInstance.post("/locations/details", location);
        iziToast.success({
          title: "Success",
          message: "Location created successfully",
          position: "topRight",
        });
      }

      // Trigger the onSuccess callback after creating or editing
      onSuccess();
      onClose(); // Close the modal after the operation
    } catch (error) {
      console.error("Error saving location:", error);
      iziToast.error({
        title: "Error",
        message: "Failed to save location",
        position: "topRight",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Title</label>
        <input
          type="text"
          name="title"
          value={location.title}
          onChange={handleInputChange}
          className="form-control"
          required
        />
      </div>
      <div className="form-group">
        <label>City</label>
        <input
          type="text"
          name="city"
          value={location.city}
          onChange={handleInputChange}
          className="form-control"
          required
        />
      </div>
      <div className="form-group">
        <label>State</label>
        <input
          type="text"
          name="state"
          value={location.state}
          onChange={handleInputChange}
          className="form-control"
          required
        />
      </div>
      <div className="form-group">
        <label>Country</label>
        <input
          type="text"
          name="country"
          value={location.country}
          onChange={handleInputChange}
          className="form-control"
          required
        />
      </div>
      <div className="form-group">
        <label>Latitude</label>
        <input
          type="text"
          name="latitude"
          value={location.latitude}
          onChange={handleInputChange}
          className="form-control"
          required
        />
      </div>
      <div className="form-group">
        <label>Longitude</label>
        <input
          type="text"
          name="longitude"
          value={location.longitude}
          onChange={handleInputChange}
          className="form-control"
          required
        />
      </div>
      <div className="form-group">
        <label>Radius (km)</label>
        <input
          type="number"
          name="radius"
          value={location.radius}
          onChange={handleInputChange}
          className="form-control"
          required
        />
      </div>
      <div className="form-group">
        <label>Status</label>
        <input
          type="checkbox"
          name="status"
          checked={location.status}
          onChange={(e) =>
            setLocation((prevState) => ({
              ...prevState,
              status: e.target.checked,
            }))
          }
        />
      </div>
      <button type="submit" className="btn btn-primary">
        {initialLocation ? "Update Location" : "Save Location"}
      </button>
    </form>
  );
};

export default AddEditLocation;
