import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import iziToast from "izitoast";

const AdminProfile = () => {
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(!edit);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { filepath, apipath } = useAuth();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    address: "",
    city: "",
    state: "",
    country: "",
    dob: "",
  });

  const userId = localStorage.getItem("adminId");

  // Function to check if user is logged in
  const checkLoginStatus = () => {
    const token = localStorage.getItem("usertoken");
    setIsLoggedIn(!!token); // Set login status based on token presence
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userResponse = await axios.get(`${apipath}/users/details/${userId}`);
        if (userResponse.data.user) {
          const user = userResponse.data.user;
          if (user.dob) {
            const formattedDob = new Date(user.dob).toISOString().split("T")[0];
            setUserDetails((prevDetails) => ({
              ...prevDetails,
              ...user,
              dob: formattedDob,
            }));
          } else {
            setUserDetails((prevDetails) => ({
              ...prevDetails,
              ...user,
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching user details or orders:", error);
      }
    };

    fetchUserDetails();
  }, [userId, apipath]);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Convert dob to timestamp
    const { dob, ...otherDetails } = userDetails;
    const dobTimestamp = dob ? new Date(dob).getTime() : null;
    const userDetailsWithTimestamp = { ...otherDetails, dob: dobTimestamp };

    try {
      const response = await axios.put(
        `${apipath}/users/details/${userId}`,
        userDetailsWithTimestamp,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        iziToast.success({
          title: "Success",
          message: "Profile updated successfully",
          position: "topCenter",
          timeout: 2000,
        });
        navigate("/dashboard");
      } else {
        throw new Error("Failed to update profile");
      }
    } catch (error) {
      console.error("Error submitting user details:", error);
      iziToast.error({
        title: "Error",
        message: "Failed to update profile. Please try again later.",
        position: "topCenter",
        timeout: 2000,
      });
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container">
          <div className="col-lg-10 mx-auto congrats profile">
            <div className="d-flex justify-content-between align-items-center profile-header">
              <div className="profile-icon">
                <div>
                  <img src={`${process.env.PUBLIC_URL}/imgs/user-icon.png`} alt="" />
                </div>
                <div className="mx-4">
                  <h5>{`${userDetails.firstName} ${userDetails.lastName}`}</h5>
                  <p>{userDetails.email}</p>
                </div>
              </div>
              <div>
                <span onClick={handleEdit} className="hand-icon">
                  Edit
                </span>
              </div>
            </div>
            <h5>Personal Information</h5>
            {edit ? (
              <div>
                <form className="row mt-3" onSubmit={handleSubmit}>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>First Name</label>
                      <input
                        type="text"
                        placeholder="First Name"
                        className="form-control bg-transparent"
                        name="firstName"
                        maxLength="100"
                        value={userDetails.firstName}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>Last Name</label>
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="form-control bg-transparent"
                        name="lastName"
                        maxLength="100"
                        value={userDetails.lastName}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>Email</label>
                      <input
                        type="email"
                        placeholder="Enter Email"
                        className="form-control bg-transparent"
                        name="email"
                        value={userDetails.email}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>Phone</label>
                      <input
                        type="text"
                        placeholder="Phone"
                        className="form-control bg-transparent"
                        name="phone"
                        value={userDetails.phone}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>Address</label>
                      <input
                        type="text"
                        placeholder="Address"
                        className="form-control bg-transparent"
                        name="address"
                        maxLength="100"
                        value={userDetails.address}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>City</label>
                      <input
                        type="text"
                        placeholder="City"
                        className="form-control bg-transparent"
                        name="city"
                        maxLength="100"
                        value={userDetails.city}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>State</label>
                      <input
                        type="text"
                        placeholder="State"
                        className="form-control bg-transparent"
                        name="state"
                        maxLength="100"
                        value={userDetails.state}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>Country</label>
                      <input
                        type="text"
                        placeholder="Country"
                        className="form-control bg-transparent"
                        name="country"
                        maxLength="100"
                        value={userDetails.country}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>Gender</label>
                      <select
                        className="form-control bg-transparent"
                        name="gender"
                        value={userDetails.gender}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Gender</option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>DOB</label>
                      <input
                        type="date"
                        placeholder="DOB"
                        className="form-control bg-transparent"
                        name="dob"
                        value={userDetails.dob}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="congrats-button mt-4">
                    <button type="submit">Update</button>
                  </div>
                </form>
              </div>
            ) : (
              <div className="row mt-3">
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>First Name</p>
                    <p>{userDetails.firstName}</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Last Name</p>
                    <p>{userDetails.lastName}</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Email</p>
                    <p>{userDetails.email}</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Phone</p>
                    <p>{userDetails.phone}</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Address</p>
                    <p>{userDetails.address}</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>City</p>
                    <p>{userDetails.city}</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>State</p>
                    <p>{userDetails.state}</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Country</p>
                    <p>{userDetails.country}</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Gender</p>
                    <p>{userDetails.gender}</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>DOB</p>
                    <p>{userDetails.dob}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminProfile;
