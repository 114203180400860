import React, { useEffect, useState } from 'react';
import SideBar from './SideBar';
import Navbar from './Navbar';
import protectedApiInstance from '../utils/protectedApiInstance';
import iziToast from 'izitoast';
import Pagination from '../components/Pagination';
import { Modal } from 'react-bootstrap';

const DriverHelpSupportContentsPage = () => {
    const [contents, setContents] = useState([]);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedOptionID, setSelectedOptionID] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const [newContent, setNewContent] = useState({ title: '', description: '', refOption: '' });
    const [showEditModal, setShowEditModal] = useState(false);
    const [editContent, setEditContent] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [contentIdToDelete, setContentIdToDelete] = useState(null);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        fetchContents();
        fetchOptions(); // Fetch options for the dropdown
    }, [currentPage, pageSize, searchQuery, selectedOptionID]);

    const fetchContents = async () => {
        try {
            setIsLoading(true);
            const response = await protectedApiInstance.get('/admin/help-and-support/driver/contents', {
                params: {
                    page: currentPage,
                    pageSize,
                    search: searchQuery,
                    optionID: selectedOptionID,
                },
            });
            setContents(response.data.contents);
            setTotalItems(response.data.pagination.totalItems);
            setTotalPages(response.data.pagination.totalPages);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching contents:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to fetch contents',
                position: 'topRight',
            });
            setContents([]);
            setTotalItems(0);
            setTotalPages(0);
            setIsLoading(false);
        }
    };

    const fetchOptions = async () => {
        try {
            const response = await protectedApiInstance.get('/admin/help-and-support/driver/options');
            setOptions(response.data.options);
        } catch (error) {
            console.error('Error fetching options:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to fetch options',
                position: 'topRight',
            });
        }
    };

    const addContent = async () => {
        if (!newContent.title.trim() || !newContent.description.trim() || !newContent.refOption) {
            iziToast.error({
                title: 'Error',
                message: 'All fields are required',
                position: 'topRight',
            });
            return;
        }

        try {
            setIsLoading(true);
            await protectedApiInstance.post('/admin/help-and-support/driver/contents', newContent);
            iziToast.success({
                title: 'Success',
                message: 'Content added successfully',
                position: 'topRight',
            });
            setShowAddModal(false);
            setNewContent({ title: '', description: '', refOption: '' });
            fetchContents();
        } catch (error) {
            console.error('Error adding content:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to add content',
                position: 'topRight',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const editContentHandler = async () => {
        if (!editContent.title.trim() || !editContent.description.trim() || !editContent.refOption) {
            iziToast.error({
                title: 'Error',
                message: 'All fields are required',
                position: 'topRight',
            });
            return;
        }

        try {
            setIsLoading(true);
            await protectedApiInstance.put(`/admin/help-and-support/driver/contents/${editContent._id}`, editContent);
            iziToast.success({
                title: 'Success',
                message: 'Content updated successfully',
                position: 'topRight',
            });
            setShowEditModal(false);
            setEditContent(null);
            fetchContents();
        } catch (error) {
            console.error('Error updating content:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to update content',
                position: 'topRight',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const deleteContent = async () => {
        try {
            setIsLoading(true);
            await protectedApiInstance.delete(`/admin/help-and-support/driver/contents/${contentIdToDelete}`);
            iziToast.success({
                title: 'Success',
                message: 'Content deleted successfully',
                position: 'topRight',
            });
            setShowDeleteModal(false);
            fetchContents();
        } catch (error) {
            console.error('Error deleting content:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to delete content',
                position: 'topRight',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const swapPosition = async (id, direction) => {
        try {
            setIsLoading(true);
            await protectedApiInstance.post('/admin/help-and-support/driver/contents/swap', { id, direction });
            iziToast.success({
                title: 'Success',
                message: 'Position swapped successfully',
                position: 'topRight',
            });
            fetchContents();
        } catch (error) {
            console.error('Error swapping position:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to swap position',
                position: 'topRight',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageSizeChange = (e) => {
        const newPageSize = parseInt(e.target.value);
        setPageSize(newPageSize);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />
            <section className="home">
                <div className="container">
                    <div className="row foot-tablerow">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>Driver Help & Support Contents</h5>
                            <button className="btn btn-primary" onClick={() => setShowAddModal(true)}>
                                Add Content
                            </button>
                        </div>

                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                {/* Table Controls */}
                                <div className="table-controls d-flex justify-content-between mb-3">
                                    <div className="d-flex align-items-center">
                                        <select
                                            className="form-select mr-2"
                                            value={selectedOptionID}
                                            onChange={(e) => setSelectedOptionID(e.target.value)}
                                        >
                                            <option value="">All Options</option>
                                            {options.map((option) => (
                                                <option key={option._id} value={option._id}>
                                                    {option.title}
                                                </option>
                                            ))}
                                        </select>
                                        <select
                                            id="entriesPerPage"
                                            className="form-select mr-2"
                                            onChange={handlePageSizeChange}
                                            value={pageSize}
                                        >
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                        <label htmlFor="entriesPerPage" className="mb-0">
                                            Entries per page
                                        </label>
                                    </div>

                                    <div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search by title"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </div>

                                {/* Table */}
                                <table id="datatable" className="table1">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Reference Option</th>
                                            <th>Position</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contents?.length > 0 ? (
                                            contents.map((content, index) => (
                                                <tr key={content._id}>
                                                    <td className="text-center">
                                                        {(currentPage - 1) * pageSize + index + 1}
                                                    </td>
                                                    <td>{content.title}</td>
                                                    <td>{content.description}</td>
                                                    <td>{content.refOption?.title || 'N/A'}</td>
                                                    <td>{content.position}</td>
                                                    <td>
                                                        <i
                                                            className={`fa-solid fa-arrow-up ${content.position === 1 ? 'disabled' : ''
                                                                }`}
                                                            onClick={() =>
                                                                content.position > 1 && swapPosition(content._id, 'up')
                                                            }
                                                            style={{
                                                                cursor: content.position === 1 ? 'not-allowed' : 'pointer',
                                                                marginRight: '10px',
                                                                color: content.position === 1 ? 'grey' : 'black',
                                                            }}
                                                        ></i>
                                                        <i
                                                            className={`fa-solid fa-arrow-down ${content.last === true ? 'disabled' : ''
                                                                }`}
                                                            onClick={() =>
                                                                content.last !== true &&
                                                                swapPosition(content._id, 'down')
                                                            }
                                                            style={{
                                                                cursor:
                                                                    content.last === true
                                                                        ? 'not-allowed'
                                                                        : 'pointer',
                                                                marginRight: '10px',
                                                                color: content.last === true ? 'grey' : 'black',
                                                            }}
                                                        ></i>
                                                        <i
                                                            className="fa-solid fa-pen-to-square"
                                                            onClick={() => {
                                                                setEditContent(content);
                                                                setShowEditModal(true);
                                                            }}
                                                            style={{ cursor: 'pointer', marginRight: '10px' }}
                                                        ></i>
                                                        <i
                                                            className="fa-solid fa-trash"
                                                            onClick={() => {
                                                                setContentIdToDelete(content._id);
                                                                setShowDeleteModal(true);
                                                            }}
                                                            style={{ cursor: 'pointer', color: 'red' }}
                                                        ></i>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6" className="text-center">
                                                    No contents found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                {/* Pagination */}
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <div>
                                        {contents?.length > 0
                                            ? `Showing entries ${(currentPage - 1) * pageSize + 1} to ${Math.min(
                                                currentPage * pageSize,
                                                totalItems
                                            )} of ${totalItems} entries`
                                            : 'Showing entries 0 to 0 of 0 entries'}
                                    </div>
                                    <Pagination
                                        currentPage={contents?.length > 0 ? currentPage : 0}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Add Content Modal */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Content</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="contentTitle" className="form-label">
                            Title
                        </label>
                        <input
                            id="contentTitle"
                            type="text"
                            className="form-control"
                            placeholder="Enter content title"
                            value={newContent.title}
                            onChange={(e) => setNewContent({ ...newContent, title: e.target.value })}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="contentDescription" className="form-label">
                            Description
                        </label>
                        <textarea
                            id="contentDescription"
                            className="form-control"
                            placeholder="Enter content description"
                            value={newContent.description}
                            onChange={(e) => setNewContent({ ...newContent, description: e.target.value })}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="contentRefOption" className="form-label">
                            Reference Option
                        </label>
                        <select
                            id="contentRefOption"
                            className="form-select"
                            value={newContent.refOption || ''}
                            onChange={(e) => setNewContent({ ...newContent, refOption: e.target.value })}
                        >
                            <option value="">Select Reference Option</option>
                            {options.map((option) => (
                                <option key={option._id} value={option._id}>
                                    {option.title}
                                </option>
                            ))}
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="modal-footer-btn" onClick={addContent} disabled={isLoading}>
                        {isLoading ? 'Adding...' : 'Add'}
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Edit Content Modal */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Content</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="editContentTitle" className="form-label">
                            Title
                        </label>
                        <input
                            id="editContentTitle"
                            type="text"
                            className="form-control"
                            placeholder="Enter content title"
                            value={editContent?.title || ''}
                            onChange={(e) => setEditContent({ ...editContent, title: e.target.value })}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="editContentDescription" className="form-label">
                            Description
                        </label>
                        <textarea
                            id="editContentDescription"
                            className="form-control"
                            placeholder="Enter content description"
                            value={editContent?.description || ''}
                            onChange={(e) => setEditContent({ ...editContent, description: e.target.value })}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="editContentRefOption" className="form-label">
                            Reference Option
                        </label>
                        <select
                            id="editContentRefOption"
                            className="form-select"
                            value={editContent?.refOption?._id || ''} // Use refOption._id for the dropdown value
                            onChange={(e) =>
                                setEditContent({
                                    ...editContent,
                                    refOption: options.find((option) => option._id === e.target.value), // Set the entire object
                                })
                            }
                        >
                            <option value="">Select Reference Option</option>
                            {options.map((option) => (
                                <option key={option._id} value={option._id}>
                                    {option.title}
                                </option>
                            ))}
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="modal-footer-btn" onClick={editContentHandler} disabled={isLoading}>
                        {isLoading ? 'Updating...' : 'Update'}
                    </button>
                </Modal.Footer>
            </Modal>


            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Content</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this content?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={deleteContent} disabled={isLoading}>
                        {isLoading ? 'Deleting...' : 'Delete'}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DriverHelpSupportContentsPage;
