import React, { useState, useEffect } from 'react';
import SideBar from './SideBar';
import Navbar from './Navbar';
import ReferBonusModal from './ReferBonusModal';
import protectedApiInstance from "../utils/protectedApiInstance";
import iziToast from 'izitoast';

const ReferBonusPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [bonusValues, setBonusValues] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchBonusValues();
    }, []);

    const fetchBonusValues = async () => {
        try {
            setIsLoading(true);
            setError('');
            const response = await protectedApiInstance.get('/admin/refer/bonuses');

            if (response.status === 200) {
                setBonusValues(response.data.data);
            } else {
                throw new Error('Failed to fetch referral bonuses.');
            }
        } catch (error) {
            console.error('Error fetching referral bonuses:', error);
            setError('Failed to fetch referral bonuses. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleSaveBonusValues = async (updatedValues) => {
        try {
            const response = await protectedApiInstance.put('/admin/refer/bonuses', updatedValues);
            if (response.status === 200) {
                setBonusValues(updatedValues);
                iziToast.success({
                    title: 'Success',
                    message: 'Referral bonuses updated successfully.',
                    position: 'topRight',
                });
            } else {
                throw new Error('Failed to update referral bonuses.');
            }
        } catch (error) {
            console.error('Error updating referral bonuses:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to update referral bonuses.',
                position: 'topRight',
            });
        } finally {
            handleCloseModal();
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />
            <section className="home">
                <div className="container">
                    <div className="row foot-tablerow">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>Referral Bonuses</h5>
                            <button className="btn btn-primary" onClick={handleShowModal} disabled={isLoading}>
                                Edit Referral Bonuses
                            </button>
                        </div>

                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4">
                                {isLoading ? (
                                    <p>Loading referral bonuses...</p>
                                ) : error ? (
                                    <div className="alert alert-danger">
                                        <p>{error}</p>
                                        <button className="btn btn-secondary" onClick={fetchBonusValues}>
                                            Retry
                                        </button>
                                    </div>
                                ) : (
                                    <table className="table table-bordered">
                                        <thead className="table-dark">
                                            <tr>
                                                <th>Referrer Type</th>
                                                <th>Bonus Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* Referral Bonuses */}
                                            <tr>
                                                <td>User → User Bonus (EtoCoins)</td>
                                                <td>{bonusValues.new_user_bonus_from_user}</td>
                                            </tr>
                                            <tr>
                                                <td>Driver → Driver Bonus (Wallet)</td>
                                                <td>{bonusValues.new_driver_bonus_from_driver}</td>
                                            </tr>
                                            <tr>
                                                <td>Driver → User Bonus (EtoCoins)</td>
                                                <td>{bonusValues.new_user_bonus_from_driver}</td>
                                            </tr>
                                            <tr>
                                                <td>User → Driver Bonus (Wallet)</td>
                                                <td>{bonusValues.new_driver_bonus_from_user}</td>
                                            </tr>

                                            {/* Referrer Bonuses */}
                                            <tr>
                                                <td>Referrer (User → User) (EtoCoins)</td>
                                                <td>{bonusValues.referrer_bonus_user_to_user}</td>
                                            </tr>
                                            <tr>
                                                <td>Referrer (Driver → Driver) (Wallet)</td>
                                                <td>{bonusValues.referrer_bonus_driver_to_driver}</td>
                                            </tr>
                                            <tr>
                                                <td>Referrer (User → Driver) (EtoCoins)</td>
                                                <td>{bonusValues.referrer_bonus_user_to_driver}</td>
                                            </tr>
                                            <tr>
                                                <td>Referrer (Driver → User) (Wallet)</td>
                                                <td>{bonusValues.referrer_bonus_driver_to_user}</td>
                                            </tr>

                                            {/* Bonuses Without Referrer */}
                                            <tr>
                                                <td>New User Bonus (No Referrer) (EtoCoins)</td>
                                                <td>{bonusValues.new_user_bonus_without_referrer}</td>
                                            </tr>
                                            <tr>
                                                <td>New Driver Bonus (No Referrer) (Wallet)</td>
                                                <td>{bonusValues.new_driver_bonus_without_referrer}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {bonusValues && (
                <ReferBonusModal
                    show={showModal}
                    onClose={handleCloseModal}
                    onSave={handleSaveBonusValues}
                    initialValues={bonusValues}
                    fetchBonusValues={fetchBonusValues}
                />
            )}
        </>
    );
};

export default ReferBonusPage;
