import React, { useState, useEffect } from "react";
import iziToast from "izitoast";
import protectedApiInstance from "../utils/protectedApiInstance";

const AddEditPromotion = ({
  promotion: initialPromotion,
  onClose,
  onSuccess,
}) => {
  const [promotion, setPromotion] = useState({
    title: "",
    message: "",
    image: "",
    targetAudience: "all",
    medium: "FCM",
    isScheduled: false,
    scheduledAt: "",
    customUserIds: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (initialPromotion) {
      setPromotion({
        ...initialPromotion,
        isScheduled: initialPromotion.isScheduled || false,
        scheduledAt: initialPromotion.scheduledAt || "",
      });
    }
  }, [initialPromotion]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setPromotion((prevState) => ({ ...prevState, [name]: newValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return; // Prevent double-click
    setIsSubmitting(true);

    try {
      const payload = { ...promotion };
      if (promotion.isScheduled && promotion.scheduledAt) {
        const utcDate = new Date(promotion.scheduledAt);
        if (!isNaN(utcDate)) {
          payload.scheduledAt = utcDate.toISOString();
        } else {
          iziToast.error({
            title: "Error",
            message: "Invalid scheduled time.",
            position: "topRight",
          });
          setIsSubmitting(false);
          return;
        }
      } else {
        delete payload.scheduledAt;
      }

      if (initialPromotion) {
        await protectedApiInstance.put(
          `/admin/promotions/${initialPromotion._id}`,
          payload
        );
        iziToast.success({
          title: "Success",
          message: "Promotion updated successfully",
          position: "topRight",
        });
      } else {
        await protectedApiInstance.post("/admin/promotions", payload);
        iziToast.success({
          title: "Success",
          message: "Promotion created successfully",
          position: "topRight",
        });
      }

      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error saving promotion:", error?.response?.data?.error);
      const message =
        error?.response?.data?.error || "Failed to save promotion";

      iziToast.error({
        title: "Error",
        message,
        position: "topRight",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Title</label>
        <input
          type="text"
          name="title"
          value={promotion.title}
          onChange={handleInputChange}
          className="form-control"
          required
        />
      </div>

      <div className="form-group">
        <label>Message</label>
        <textarea
          name="message"
          value={promotion.message}
          onChange={handleInputChange}
          className="form-control"
          required
        ></textarea>
      </div>

      <div className="form-group">
        <label>Target Audience</label>
        <select
          name="targetAudience"
          value={promotion.targetAudience}
          onChange={handleInputChange}
          className="form-select"
        >
          <option value="all">All</option>
          <option value="users">Users</option>
          <option value="drivers">Drivers</option>
          <option value="custom">Custom</option>
        </select>
      </div>

      {promotion.targetAudience === "custom" && (
        <div className="form-group">
          <label>Custom User IDs (comma-separated)</label>
          <textarea
            name="customUserIds"
            className="form-control"
            placeholder="e.g. 65f1a23456c78..., 65f1b98765c21..."
            value={promotion.customUserIds.join(",")}
            onChange={(e) =>
              setPromotion((prev) => ({
                ...prev,
                customUserIds: e.target.value
                  .split(",")
                  .map((id) => id.trim())
                  .filter((id) => id !== ""),
              }))
            }
          ></textarea>
        </div>
      )}

      <div className="form-group">
        <label>Medium</label>
        <select
          name="medium"
          value={promotion.medium}
          onChange={handleInputChange}
          className="form-select"
        >
          <option value="FCM">FCM</option>
          {/* <option value="Whatsapp">Whatsapp</option> */}
        </select>
      </div>

      <div className="form-group form-check mb-2">
        <input
          type="checkbox"
          name="isScheduled"
          checked={promotion.isScheduled}
          onChange={handleInputChange}
          className="form-check-input"
          id="isScheduled"
        />
        <label className="form-check-label" htmlFor="isScheduled">
          Schedule Later
        </label>
      </div>

      {promotion.isScheduled && (
        <div className="form-group">
          <label>Schedule Time (IST)</label>
          <input
            type="datetime-local"
            name="scheduledAt"
            value={promotion.scheduledAt}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
      )}

      <div className="form-group">
        <label>Image URL</label>
        <input
          type="text"
          name="image"
          value={promotion.image}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>


      <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
        {isSubmitting
          ? initialPromotion
            ? "Updating..."
            : "Saving..."
          : initialPromotion
          ? "Update Promotion"
          : "Save Promotion"}
      </button>
    </form>
  );
};

export default AddEditPromotion;
