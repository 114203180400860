import React from "react";
import "./Pagination.css"; // Assuming we create a CSS file for styles

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <div className="pagination-container">
      <button
        className="page-nav-btn"
        onClick={() => onPageChange(1)}
        disabled={currentPage === 1 || totalPages === 0}
      >
        &lt;&lt;
      </button>
      <button
        className="page-nav-btn"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1 || totalPages === 0}
      >
        &lt;
      </button>
      {totalPages > 0 &&
        getPageNumbers().map((page) => (
          <button
            key={page}
            className={`page-number-btn ${page === currentPage ? "active" : ""}`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        ))}
      <button
        className="page-nav-btn"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages || totalPages === 0}
      >
        &gt;
      </button>
      <button
        className="page-nav-btn"
        onClick={() => onPageChange(totalPages)}
        disabled={currentPage === totalPages || totalPages === 0}
      >
        &gt;&gt;
      </button>
    </div>
  );
};

export default Pagination;
