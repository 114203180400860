import AdminTable from "./admin/AdminTable";
import BannersEdit from "./admin/BannersEdit";
import BlogsEdit from "./admin/BlogsEdit";
import ContactEdit from "./admin/ContactEdit";
import CourseEdit from "./admin/CourseEdit";
import DetailsEdit from "./admin/DetailsEdit";
import FavIcon from "./admin/FavIcon";
import FooterEdit from "./admin/FooterEdit";
import HomeEdit from "./admin/HomeEdit";
import LogoEdit from "./admin/LogoEdit";
import DashboardPage from "./admin/DashboardPage";
import NavbarEdit from "./admin/NavbarEdit";
import PrivacyEdit from "./admin/PrivacyEdit";
import ServiceEdit from "./admin/ServiceEdit";
import TermsEdit from "./admin/TermsEdit";
import "./App.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  
} from "react-router-dom";
import Login from "./login/Login";
import Forgot from "./login/Forgot";
import Reset from "./login/Reset";
import BlogContentEdit from "./admin/BlogContentEdit";
import UsersPage from "./admin/UsersPage";
import SingleUserPage from "./admin/SingleUserPage";
import AdminProfile from "./admin/AdminProfile";
import NewRegister from "./register/NewRegister";
import DriversPage from "./admin/DriversPage";
import DriverRideDetails from "./admin/DriverRideDetails";
import DriverDetailsViewEdit from "./admin/DriverDetailsViewEdit";
import Privacypolicy from "./components/Privacypolicy";
import SupportTeam from "./components/SupportTeam";
import CouponsPage from "./admin/CouponsPage";
import LiveMap from "./admin/LiveMap";
import DriverPayout from "./admin/DriverPayout";
import RefundEdit from "./admin/RefundEdit";
import ShippingPage from "./admin/ShippingPage";
import TermsPolicy from "./components/Terms";
import ShippingPolicy from "./components/ShippingPolicy";
import RefundPolicy from "./components/RefundPolicy";
import Aboutus from "./components/Aboutus";
import ContactusEdit from "./admin/Contactus";
import FarePage from "./admin/FarePage";
import LocationsPage from "./admin/LocationsPage";
import AddEditFarePage from "./admin/AddEditFarePage";
import AddEditCouponPage from "./admin/AddEditCouponPage";
import ReferBonusPage from "./admin/ReferBonusPage";
import UserHelpSupportOptionsPage from "./admin/UserHelpSupportOptionsPage";
import DriverHelpSupportOptionsPage from "./admin/DriverHelpSupportOptionsPage";
import UserHelpSupportContentsPage from "./admin/UserHelpSupportContentsPage";
import DriverHelpSupportContentsPage from "./admin/DriverHelpSupportContentsPage";
import NotFoundPage from "./admin/NotFoundPage";
import PromotionsPage from "./admin/PromotionsPage";
import BookingsPage from "./admin/BookingsPage";
import RidePaymentsPage from "./admin/RidePaymentsPage";
import SosPage from "./admin/SosPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/dashboard" element={<DashboardPage />} />
          <Route exact path="/" element={<Login />} />
          <Route exact path="/forgot" element={<Forgot />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/drivers" element={<DriversPage />} />
          <Route
            exact
            path="/driver/:driverId"
            element={<DriverDetailsViewEdit />}
          />

          <Route exact path="/promotions" element={<PromotionsPage />} />
          <Route exact path="/table" element={<AdminTable />} />
          <Route exact path="/logo" element={<LogoEdit />} />
          <Route exact path="/favicon" element={<FavIcon />} />
          <Route exact path="/banners" element={<BannersEdit />} />

          <Route exact path="/navedit" element={<NavbarEdit />} />
          <Route exact path="/footeredit" element={<FooterEdit />} />
          <Route
            exact
            path="/driver-payout/:driverId"
            element={<DriverPayout />}
          />
          <Route exact path="/homeedit" element={<HomeEdit />} />
          {/* <Route exact path="/aboutedit" element={<AboutEdit />} />  */}
          <Route exact path="/contactusedit" element={<ContactusEdit />} />
          <Route exact path="/serviceedit" element={<ServiceEdit />} />
          <Route exact path="/contactedit" element={<ContactEdit />} />
          <Route exact path="/privacyedit" element={<PrivacyEdit />} />
          <Route exact path="/refundedit" element={<RefundEdit />} />
          <Route exact path="/shippingedit" element={<ShippingPage />} />
          <Route exact path="/termsedit" element={<TermsEdit />} />
          <Route exact path="/detailsedit" element={<DetailsEdit />} />
          <Route exact path="/coupons" element={<CouponsPage />} />
          <Route exact path="/coupons/new" element={<AddEditCouponPage />} />
          <Route exact path="/coupons/:id" element={<AddEditCouponPage />} />

          <Route exact path="/terms-condition" element={<TermsPolicy />} />
          <Route exact path="/shippingpolicy" element={<ShippingPolicy />} />
          <Route exact path="/refundpolicy" element={<RefundPolicy />} />
          <Route exact path="/privacypolicy" element={<Privacypolicy />} />
          <Route exact path="/support" element={<SupportTeam />} />
          <Route exact path="/aboutus" element={<Aboutus />} />

          <Route exact path="/users" element={<UsersPage />} />
          <Route exact path="/user-details/:id" element={<SingleUserPage />} />
          <Route exact path="/blogsedit" element={<BlogsEdit />} />
          <Route exact path="/blog-content" element={<BlogContentEdit />} />
          <Route exact path="/courseedit" element={<CourseEdit />} />
          <Route exact path="/payments" element={<RidePaymentsPage />} />
          <Route exact path="/profile/:id" element={<AdminProfile />} />

          <Route
            exact
            path="/bookings"
            element={<BookingsPage />}
          />
          <Route
            exact
            path="/driver-ride-accept"
            element={<DriverRideDetails />}
          />
          <Route exact path="/live-map/:id" element={<LiveMap />} />

          <Route exact path="/fare" element={<FarePage />} />
          <Route exact path="/fare/new" element={<AddEditFarePage />} />
          <Route exact path="/fare/:fareId" element={<AddEditFarePage />} />

          <Route exact path="/locations" element={<LocationsPage />} />

          <Route
            exact
            path="/user-help-support-options"
            element={<UserHelpSupportOptionsPage />}
          />
          <Route
            exact
            path="/user-help-support-contents"
            element={<UserHelpSupportContentsPage />}
          />
          <Route
            exact
            path="/driver-help-support-options"
            element={<DriverHelpSupportOptionsPage />}
          />
          <Route
            exact
            path="/driver-help-support-contents"
            element={<DriverHelpSupportContentsPage />}
          />

          <Route exact path="/refer-bonus" element={<ReferBonusPage />} />
          <Route exact path="/sos" element={<SosPage />} />

          <Route exact path="/register" element={<NewRegister />} />
          <Route exact path="/*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
