import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import protectedApiInstance from "../utils/protectedApiInstance";
import iziToast from "izitoast";
import SideBar from "./SideBar";
import Navbar from "./Navbar";

const AddEditFarePage = () => {
  const { fareId } = useParams();
  const [locations, setLocations] = useState([]);

  const navigate = useNavigate();

  const [fare, setFare] = useState({
    customer_convenience_fee: "",
    base_fare: {
      three_seater: { day: "", night: "" },
      whole_seater: { day: "", night: "" },
      goods: { day: "", night: "" },
      goods_with_person: { day: "", night: "" },
    },
    commission: {
      day: "",
      night: "",
    },
    night_starts: "",
    night_ends: "",
    goods_service_starts: "",
    goods_service_ends: "",
    current: false,
    distance_fare_structure: [],
    duration_fare_structure: [],
    location: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const fetchLocations = useCallback(async () => {
    try {
      const response = await protectedApiInstance.get("/locations/details");
      setLocations(response.data.locations);
    } catch (error) {
      iziToast.error({
        title: "Error",
        message: "Failed to fetch locations",
        position: "topRight",
      });
    }
  }, []);

  const fetchFareDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await protectedApiInstance.get(`/fares/${fareId}`);
      setFare(response.data.fare);
    } catch (error) {
      iziToast.error({
        title: "Error",
        message: "Failed to fetch fare details",
        position: "topRight",
      });
    } finally {
      setIsLoading(false);
    }
  }, [fareId]);

  useEffect(() => {
    fetchLocations();
    if (fareId) {
      fetchFareDetails();
    }
  }, [fareId, fetchLocations, fetchFareDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFare((prevFare) => ({ ...prevFare, [name]: value }));
  };

  const addBreakpoint = (type) => {
    setFare((prevFare) => ({
      ...prevFare,
      [type]: [
        ...prevFare[type],
        { min: "", max: "", day_fare: "", night_fare: "" },
      ],
    }));
  };

  const removeBreakpoint = (type, index) => {
    setFare((prevFare) => ({
      ...prevFare,
      [type]: prevFare[type].filter((_, i) => i !== index),
    }));
  };

  const handleBreakpointChange = (type, index, field, value) => {
    setFare((prevFare) => {
      const updatedBreakpoints = [...prevFare[type]];
      updatedBreakpoints[index][field] = value;
      return { ...prevFare, [type]: updatedBreakpoints };
    });
  };

  const handleBaseFareChange = (type, period, value) => {
    setFare((prevFare) => ({
      ...prevFare,
      base_fare: {
        ...prevFare.base_fare,
        [type]: { ...prevFare.base_fare[type], [period]: value },
      },
    }));
  };

  const handleCommissionChange = (key, value) => {
    setFare((prevFare) => ({
      ...prevFare,
      commission: { ...prevFare.commission, [key]: value },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (fareId) {
        await protectedApiInstance.put(`/fares/${fareId}`, fare);
        iziToast.success({
          title: "Success",
          message: "Fare updated successfully",
          position: "topRight",
        });
      } else {
        await protectedApiInstance.post("/fares", fare);
        iziToast.success({
          title: "Success",
          message: "Fare created successfully",
          position: "topRight",
        });
      }
      navigate("/fare");
    } catch (error) {
      iziToast.error({
        title: "Error",
        message: "Failed to save fare",
        position: "topRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="container mt-5">
          <h2>{fareId ? "Edit Fare Profile" : "Add Fare Profile"}</h2>
          <form onSubmit={handleSubmit} className="mt-4">
            {/* Location */}
            <div className="mb-3">
              <label htmlFor="location" className="form-label">
                Location
              </label>
              <select
                className="form-control"
                id="location"
                name="location"
                value={fare.location}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a location</option>
                {locations.map((loc) => (
                  <option key={loc._id} value={loc._id}>
                    {loc.title}
                  </option>
                ))}
              </select>
            </div>
            {/* Customer Convenience Fee */}
            <div className="mb-3">
              <label htmlFor="customer_convenience_fee" className="form-label">
                Customer Convenience Fee
              </label>
              <input
                type="number"
                className="form-control"
                id="customer_convenience_fee"
                name="customer_convenience_fee"
                value={fare.customer_convenience_fee || ""}
                onChange={handleInputChange}
                placeholder="Enter the convenience fee"
                required
              />
            </div>
            {/* Base Fare Details */}
            <h4>Base Fare</h4>
            {Object.keys(fare.base_fare).map((type) => (
              <div key={type}>
                <h5>{type.replace("_", " ").toUpperCase()}</h5>
                <div className="row mb-3">
                  <div className="col">
                    <label htmlFor={`${type}_day`} className="form-label">
                      Day Fare
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id={`${type}_day`}
                      value={fare.base_fare[type].day}
                      onChange={(e) =>
                        handleBaseFareChange(type, "day", e.target.value)
                      }
                      required
                    />
                  </div>
                  <div className="col">
                    <label htmlFor={`${type}_night`} className="form-label">
                      Night Fare
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id={`${type}_night`}
                      value={fare.base_fare[type].night}
                      onChange={(e) =>
                        handleBaseFareChange(type, "night", e.target.value)
                      }
                      required
                    />
                  </div>
                </div>
              </div>
            ))}

            {/* Commission */}
            <h4>Commission (%)</h4>
            {Object.keys(fare.commission).map((key) => (
              <div className="mb-3" key={key}>
                <label htmlFor={key} className="form-label">
                  {key.toUpperCase()} COMMISSION
                </label>
                <input
                  type="number"
                  className="form-control"
                  id={key}
                  value={fare.commission[key]}
                  onChange={(e) => handleCommissionChange(key, e.target.value)}
                  required
                />
              </div>
            ))}
            <div className="mb-3">
              <label htmlFor="night_starts" className="form-label">
                Night Start Time
              </label>
              <input
                type="time"
                className="form-control"
                id="night_starts"
                name="night_starts"
                value={fare.night_starts}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="night_ends" className="form-label">
                Night End Time
              </label>
              <input
                type="time"
                className="form-control"
                id="night_ends"
                name="night_ends"
                value={fare.night_ends}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="goods_service_starts" className="form-label">
                Goods Service Start Time
              </label>
              <input
                type="time"
                className="form-control"
                id="goods_service_starts"
                name="goods_service_starts"
                value={fare.goods_service_starts}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="goods_service_ends" className="form-label">
                Goods Service End Time
              </label>
              <input
                type="time"
                className="form-control"
                id="goods_service_ends"
                name="goods_service_ends"
                value={fare.goods_service_ends}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-check mb-4">
              <input
                type="checkbox"
                className="form-check-input"
                id="current"
                name="current"
                checked={fare.current}
                onChange={(e) =>
                  setFare((prevFare) => ({
                    ...prevFare,
                    current: e.target.checked,
                  }))
                }
              />
              <label htmlFor="current" className="form-check-label">
                Make it current profile
              </label>
            </div>

            {/* Distance Fare Structure */}
            <h4>Distance Breakpoints (in km)</h4>
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Minimum (km)</th>
                    <th>Maximum (km)</th>
                    <th>Day Fare</th>
                    <th>Night Fare</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {fare.distance_fare_structure?.map((breakpoint, index) => (
                    <tr key={`distance-${index}`}>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Minimum"
                          value={breakpoint.min}
                          onChange={(e) =>
                            handleBreakpointChange(
                              "distance_fare_structure",
                              index,
                              "min",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Maximum"
                          value={breakpoint.max}
                          onChange={(e) =>
                            handleBreakpointChange(
                              "distance_fare_structure",
                              index,
                              "max",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Day Fare"
                          value={breakpoint.day_fare}
                          onChange={(e) =>
                            handleBreakpointChange(
                              "distance_fare_structure",
                              index,
                              "day_fare",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Night Fare"
                          value={breakpoint.night_fare}
                          onChange={(e) =>
                            handleBreakpointChange(
                              "distance_fare_structure",
                              index,
                              "night_fare",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() =>
                            removeBreakpoint("distance_fare_structure", index)
                          }
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <p>
              Set the 'Maximum' value to a large number like 999999 to represent
              infinity.
            </p>
            <button
              type="button"
              className="btn btn-secondary mb-4"
              onClick={() => addBreakpoint("distance_fare_structure")}
            >
              Add Distance Breakpoint
            </button>

            {/* Time Fare Structure */}
            <h4>Duration Breakpoints (in km driver charges will be /min)</h4>
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Minimum (kms)</th>
                    <th>Maximum (kms)</th>
                    <th>Day Fare /min</th>
                    <th>Night Fare /min</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {fare.duration_fare_structure?.map((breakpoint, index) => (
                    <tr key={`time-${index}`}>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Minimum"
                          value={breakpoint.min}
                          onChange={(e) =>
                            handleBreakpointChange(
                              "duration_fare_structure",
                              index,
                              "min",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Maximum"
                          value={breakpoint.max}
                          onChange={(e) =>
                            handleBreakpointChange(
                              "duration_fare_structure",
                              index,
                              "max",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Day Fare"
                          value={breakpoint.day_fare}
                          onChange={(e) =>
                            handleBreakpointChange(
                              "duration_fare_structure",
                              index,
                              "day_fare",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Night Fare"
                          value={breakpoint.night_fare}
                          onChange={(e) =>
                            handleBreakpointChange(
                              "duration_fare_structure",
                              index,
                              "night_fare",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() =>
                            removeBreakpoint("duration_fare_structure", index)
                          }
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <p>
              Set the 'End Duration' value to a large number like 999999 to
              represent infinity.
            </p>
            <button
              type="button"
              className="btn btn-secondary mb-4"
              onClick={() => addBreakpoint("duration_fare_structure")}
            >
              Add Time Breakpoint
            </button>

            <div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                type="button"
                className="btn btn-secondary ms-2"
                onClick={() => navigate("/fare")}
                disabled={isLoading}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default AddEditFarePage;
