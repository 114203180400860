import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import TextEditor from "../components/TextEditor";
import Navbar from "./Navbar";
import iziToast from "izitoast";
import protectedApiInstance from "../utils/protectedApiInstance";

const RefundEdit = () => {
  const [refundContent, setRefundContent] = useState("");

  useEffect(() => {
    const fetchRefundPolicy = async () => {
      try {
        const response = await protectedApiInstance.get(`/refund-policy`);
        setRefundContent(response?.data?.refundPolicy?.content || "");
        iziToast.success({
          message: "Refund Policy updated successfully!",
          position: "topCenter",
        });
      } catch (error) {
        console.error("Error fetching Refund policy:", error);
      }
    };

    fetchRefundPolicy();
  }, []);

  const handleSaveRefundPolicy = async (e) => {
    e.preventDefault();
    try {
      await protectedApiInstance.post(`/refund-policy`, {
        content: refundContent,
      });
      iziToast.success({
        message: "Refund Policy updated successfully!",
        position: "topCenter",
      });
      console.log("Refund policy saved successfully");
    } catch (error) {
      console.error("Error saving Refund policy:", error);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div class="container pt-3">
          <div class="row top-barcolumn">
            <h5>Edit Refund Policy Information</h5>
            <div class="row nav-column">
              <div>
                <form className="row">
                  <div className="col-lg-12 mb-3">
                    <TextEditor
                      value={refundContent}
                      onChange={setRefundContent}
                    />
                  </div>
                  <div className="">
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={handleSaveRefundPolicy}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RefundEdit;
