import React, { useCallback, useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { Modal } from "react-bootstrap";
import moment from "moment";
import iziToast from "izitoast";
import protectedApiInstance from "../utils/protectedApiInstance";
import Pagination from "../components/Pagination";
import AddEditPromotion from "./AddEditPromotion";

const PromotionsPage = () => {
  const [promotions, setPromotions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [show, setShow] = useState(false);
  const [promoIdToDelete, setPromoIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPromotions = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await protectedApiInstance.get(`/admin/promotions`, {
        params: {
          page: currentPage,
          pageSize,
          search: searchQuery,
          sortBy: sortBy,
          sortOrder,
        },
      });
      setPromotions(response.data.promotions || []);
      setTotalPages(response.data.totalPages || 0);
    } catch (error) {
      console.error("Error fetching promotions:", error);
      setPromotions([]);
      setTotalPages(0);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, pageSize, searchQuery, sortBy, sortOrder]);

  useEffect(() => {
    fetchPromotions();
  }, [fetchPromotions]);

  const handleSort = (field) => {
    if (sortBy === field) {
      // Toggle order
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleShow = (id) => {
    setPromoIdToDelete(id);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await protectedApiInstance.delete(`/admin/promotions/${promoIdToDelete}`);
      setPromotions(
        promotions.filter((promo) => promo._id !== promoIdToDelete)
      );
      iziToast.success({ message: "Promotion deleted", position: "topRight" });
    } catch (error) {
      iziToast.error({
        message: "Error deleting promotion",
        position: "topRight",
      });
      console.error("Delete error:", error);
    } finally {
      setIsLoading(false);
      setShow(false);
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [promotionToEdit, setPromotionToEdit] = useState(null);

  const handleShowCreateModal = (promotion = null) => {
    setPromotionToEdit(promotion);
    setShowCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setPromotionToEdit(null);
    setShowCreateModal(false);
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Promotion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this promotion?
          </Modal.Body>
          <Modal.Footer>
            <button
              className="modal-footer-btn"
              onClick={handleDelete}
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Delete"}
            </button>
          </Modal.Footer>
        </Modal>

        {/* Add/Edit Promotion Modal */}
        <Modal show={showCreateModal} onHide={handleCloseCreateModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {promotionToEdit ? "Edit Promotion" : "Add Promotion"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditPromotion
              promotion={promotionToEdit}
              onClose={handleCloseCreateModal}
              onSuccess={fetchPromotions}
            />
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5>Promotions</h5>
              <button
                className="btn btn-primary"
                onClick={() => handleShowCreateModal()}
              >
                Create Promotion
              </button>
            </div>

            <div className="col-lg-12 maintable-column">
              <div
                className="container mt-4 overflow-auto"
                style={{ minHeight: "300px" }}
              >
                <div className="table-controls d-flex justify-content-between mb-3">
                  <div className="d-flex align-items-center">
                    <select
                      id="entriesPerPage"
                      className="form-select mr-2"
                      onChange={(e) => setPageSize(parseInt(e.target.value))}
                      value={pageSize}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    <label
                      htmlFor="entriesPerPage"
                      className="mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Entries per page
                    </label>
                  </div>

                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>

                <table id="datatable" className="table1">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th
                        onClick={() => handleSort("title")}
                        style={{ cursor: "pointer" }}
                      >
                        Title{" "}
                        {sortBy === "title" &&
                          (sortOrder === "asc" ? "↑" : "↓")}
                      </th>
                      <th>Message</th>
                      <th
                        onClick={() => handleSort("medium")}
                        style={{ cursor: "pointer" }}
                      >
                        Medium{" "}
                        {sortBy === "medium" &&
                          (sortOrder === "asc" ? "↑" : "↓")}
                      </th>
                      <th
                        onClick={() => handleSort("targetAudience")}
                        style={{ cursor: "pointer" }}
                      >
                        Audience{" "}
                        {sortBy === "targetAudience" &&
                          (sortOrder === "asc" ? "↑" : "↓")}
                      </th>
                      <th
                        onClick={() => handleSort("scheduledAt")}
                        style={{ cursor: "pointer" }}
                      >
                        Scheduled{" "}
                        {sortBy === "scheduledAt" &&
                          (sortOrder === "asc" ? "↑" : "↓")}
                      </th>
                      <th>Status</th>
                      <th
                        onClick={() => handleSort("createdAt")}
                        style={{ cursor: "pointer" }}
                      >
                        Created{" "}
                        {sortBy === "createdAt" &&
                          (sortOrder === "asc" ? "↑" : "↓")}
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {promotions.length > 0 ? (
                      promotions.map((promo, index) => (
                        <tr key={promo._id}>
                          <td className="text-center">
                            {(currentPage - 1) * pageSize + index + 1}
                          </td>
                          <td>{promo.title}</td>
                          <td>{promo.message}</td>
                          <td>{promo.medium}</td>
                          <td>{promo.targetAudience}</td>
                          <td>
                            {promo.isScheduled && promo.scheduledAt
                              ? new Date(promo.scheduledAt).toLocaleString()
                              : "Now"}
                          </td>
                          <td>
                            {promo.isSent ? (
                              <span className="badge bg-success">
                                Sent at{" "}
                                {moment(promo.sentAt).format(
                                  "DD-MM-YYYY HH:mm"
                                )}
                              </span>
                            ) : (
                              <span className="badge bg-warning text-dark">
                                Pending
                              </span>
                            )}
                          </td>
                          <td>
                            {moment(promo.createdAt).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            <i
                              className="fa-regular fa-pen-to-square me-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleShowCreateModal(promo)}
                            ></i>
                            <i
                              className="fa-solid fa-trash"
                              onClick={() => handleShow(promo._id)}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center py-5 text-muted">
                          No promotions found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    {promotions.length > 0
                      ? `Showing entries ${
                          (currentPage - 1) * pageSize + 1
                        } to ${Math.min(
                          currentPage * pageSize,
                          promotions.length
                        )} of ${promotions.length} entries`
                      : "Showing entries 0 to 0 of 0 entries"}
                  </div>
                  <Pagination
                    currentPage={promotions.length > 0 ? currentPage : 0}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PromotionsPage;
