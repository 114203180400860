import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import protectedApiInstance from "../utils/protectedApiInstance";
import Pagination from "../components/Pagination";
import moment from "moment";
import iziToast from "izitoast";

const BookingsPage = () => {
  const [bookings, setBookings] = useState([]);
  const [show, setShow] = useState(false);
  const [bookingIdToDelete, setBookingIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);

  // Fetch bookings when dependencies change
  useEffect(() => {
    fetchBookings();
  }, [currentPage, pageSize, searchQuery, sortOrder]);

  const fetchBookings = async () => {
    try {
      setIsLoading(true);
      const response = await protectedApiInstance.get(
        "/booking-service/details",
        {
          params: {
            page: currentPage,
            pageSize,
            search: searchQuery,
            sortBy: "createdAt",
            sortOrder,
          },
        }
      );
      setBookings(response.data.booking || []);
      setTotalPages(response.data.totalPages || 0);
    } catch (error) {
      console.error("Error fetching bookings:", error);
      setBookings([]);
      setTotalPages(0);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await protectedApiInstance.delete(
        `/booking-service/details/${bookingIdToDelete}`
      );
      setShow(false);
      iziToast.success({
        title: "Success",
        message: "Booking deleted",
        position: "topRight",
      });
      fetchBookings();
    } catch (error) {
      iziToast.error({
        title: "Error",
        message: "Failed to delete booking",
        position: "topRight",
      });
    }
  };

  const handleSortChange = (order) => {
    if (order !== sortOrder) {
      setSortOrder(order);
      setCurrentPage(1);
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleShow = (id) => {
    setBookingIdToDelete(id);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        {/* Sidebar toggle (if applicable) */}
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Modal for delete confirmation */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this booking?</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="modal-footer-btn"
              onClick={handleDelete}
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Delete"}
            </button>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            {/* Consistent header style as DriversPage */}
            <h5 style={{ color: "#007bff" }}>Booking Details</h5>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                {/* Table Controls */}
                <div className="table-controls d-flex justify-content-between mb-3">
                  <div className="d-flex align-items-center">
                    <select
                      id="entriesPerPage"
                      className="form-select mr-2"
                      onChange={(e) =>
                        setPageSize(parseInt(e.target.value, 10))
                      }
                      value={pageSize}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    <label
                      htmlFor="entriesPerPage"
                      className="mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Entries per page
                    </label>
                  </div>
                  <div className="d-flex align-items-center gap-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>

                {/* Table */}
                <table className="table1" id="datatable">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Booking ID</th>
                      <th>Service Type</th>
                      <th>Status</th>
                      <th>Payment</th>
                      <th>Ride Distance</th>
                      <th>Total</th>
                      <th className="d-flex align-items-center">
                        Date
                        <div className="d-flex flex-column align-items-center">
                          <button
                            className={`btn btn-link px-1 py-0 ${
                              sortOrder === "asc"
                                ? "text-primary"
                                : "text-secondary"
                            }`}
                            onClick={() => handleSortChange("asc")}
                            aria-label="Sort Ascending"
                          >
                            <i className="fas fa-arrow-up"></i>
                          </button>
                          <button
                            className={`btn btn-link px-1 py-0 ${
                              sortOrder === "desc"
                                ? "text-primary"
                                : "text-secondary"
                            }`}
                            onClick={() => handleSortChange("desc")}
                            aria-label="Sort Descending"
                          >
                            <i className="fas fa-arrow-down"></i>
                          </button>
                        </div>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.length > 0 ? (
                      bookings.map((booking, index) => (
                        <tr key={booking._id}>
                          <td>
                            {(currentPage - 1) * pageSize + index + 1}
                          </td>
                          <td>{booking.bookingOrderId}</td>
                          <td>{booking.serviceType}</td>
                          <td>
                            <span
                              className={`badge bg-${
                                booking.bookingStatus === "Completed"
                                  ? "success"
                                  : "danger"
                              }`}
                            >
                              {booking.bookingStatus}
                            </span>
                          </td>
                          <td>{booking.paymentStatus}</td>
                          <td>{booking.rideDistance}</td>
                          <td>{booking.totalAmount}</td>
                          <td>
                            {moment(booking.serviceDate).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            <i
                              className="fa-solid fa-trash"
                              onClick={() => handleShow(booking._id)}
                              style={{ cursor: "pointer", marginRight: 8 }}
                              title="Delete Booking"
                            ></i>
                            <Link to={`/live-map/${booking._id}`}>
                              <i
                                className="bi bi-geo-alt-fill"
                                title="View on Map"
                              ></i>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" className="text-center">
                          No bookings found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {/* Footer: Entries & Pagination */}
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    {bookings.length > 0
                      ? `Showing entries ${
                          (currentPage - 1) * pageSize + 1
                        } to ${Math.min(
                          currentPage * pageSize,
                          bookings.length
                        )} of ${bookings.length} entries`
                      : "Showing entries 0 to 0 of 0 entries"}
                  </div>
                  <Pagination
                    currentPage={bookings.length > 0 ? currentPage : 0}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingsPage;
